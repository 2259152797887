import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "buttons"
};
import { ref } from "vue";
export default {
  __name: 'PassInput',
  emits: ['success'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const payPwd = ref('');
    const confirmOrder = () => {
      emit('success', payPwd.value);
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_input, {
        modelValue: payPwd.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => payPwd.value = $event),
        style: {
          "width": "300px"
        },
        type: "password",
        placeholder: _ctx.$t('please_enter_transaction_password'),
        "show-password": "",
        maxlength: "6",
        size: "large",
        onInput: _cache[1] || (_cache[1] = $event => payPwd.value = payPwd.value.replace(/[^\d]/g, ''))
      }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_button, {
        color: "#56F9AA",
        style: {
          "color": "#333"
        },
        block: "",
        round: "",
        onClick: confirmOrder
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('Sure')), 1)]),
        _: 1
      })])]);
    };
  }
};