import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "pwd-item"
};
const _hoisted_2 = {
  class: "pwd-item"
};
const _hoisted_3 = {
  class: "pwd-item"
};
import { updatePwd } from '@/http';
import { ref } from 'vue';
import { showToast } from 'vant';
import { router } from '@/router';
export default {
  __name: 'ChangePayPwd',
  setup(__props) {
    const oldPwd = ref('');
    const password = ref('');
    const password_confirmation = ref('');
    const _updatePwd = async () => {
      const {
        msg,
        code
      } = await updatePwd({
        type: 2,
        old: oldPwd.value,
        password: password.value,
        password_confirmation: password_confirmation.value
      });
      showToast(msg);
      if (code === 200) {
        router.back();
      }
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('original_password')), 1), _createVNode(_component_van_field, {
        class: "pwd-item__input",
        maxlength: 6,
        type: "password",
        modelValue: oldPwd.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => oldPwd.value = $event),
        placeholder: _ctx.$t('enter_the_original_password')
      }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('new_password')), 1), _createVNode(_component_van_field, {
        class: "pwd-item__input",
        maxlength: 6,
        type: "password",
        modelValue: password.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => password.value = $event),
        placeholder: _ctx.$t('enter_new_password_enter_6_digit_number')
      }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('confirm_password')), 1), _createVNode(_component_van_field, {
        class: "pwd-item__input",
        maxlength: 6,
        type: "password",
        modelValue: password_confirmation.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => password_confirmation.value = $event),
        placeholder: _ctx.$t('re_enter_the_new_password')
      }, null, 8, ["modelValue", "placeholder"])]), _createVNode(_component_van_button, {
        color: "#56F9AA",
        round: "",
        block: "",
        onClick: _updatePwd,
        style: {
          "color": "#333"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('confirm_modification')), 1)]),
        _: 1
      })], 64);
    };
  }
};