import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "max-width": "440px",
    "margin": "0 auto"
  }
};
const _hoisted_2 = {
  class: "pa-18"
};
const _hoisted_3 = {
  class: "pa-18"
};
import { router } from '@/router';
import ChangeLoginPwd from '@/components/ChangeLoginPwd/ChangeLoginPwd.vue';
import ChangePayPwd from '@/components/ChangePayPwd/ChangePayPwd.vue';
import { ref } from 'vue';
export default {
  __name: 'changePwd',
  setup(__props) {
    const active = ref(0);
    const back = () => {
      router.back();
    };
    return (_ctx, _cache) => {
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_tabs, {
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        color: "#56F9AA",
        sticky: "",
        background: "rgba(0,0,0,0)"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: _ctx.$t('login_password')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(ChangeLoginPwd)])]),
          _: 1
        }, 8, ["title"]), _createVNode(_component_van_tab, {
          title: _ctx.$t('transaction_password')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(ChangePayPwd)])]),
          _: 1
        }, 8, ["title"])]),
        _: 1
      }, 8, ["active"])]);
    };
  }
};