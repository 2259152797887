import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "tabs"
};
import { router } from '@/router';
import { ref } from 'vue';
import DepositRecord from '@/components/DepositRecord/DepositRecord.vue';
import WithdrawalRecord from '@/components/WithdrawalRecord/WithdrawalRecord.vue';
import TransactionHistory from '@/components/TransactionHistory/TransactionHistory.vue';
export default {
  __name: 'historyBill',
  setup(__props) {
    const active = ref(0);
    const back = () => {
      router.back();
    };
    let show = ref(false);
    const scroll = e => {
      show.value = e.scrollTop > 10;
    };
    return (_ctx, _cache) => {
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_tabs, {
        "offset-top": "0",
        onScroll: scroll,
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        color: "#56F9AA",
        sticky: "",
        background: _unref(show) ? '#0E1825' : 'rgba(0,0,0,0)'
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: _ctx.$t('deposit')
        }, {
          default: _withCtx(() => [_createVNode(DepositRecord)]),
          _: 1
        }, 8, ["title"]), _createVNode(_component_van_tab, {
          title: _ctx.$t('withdraw')
        }, {
          default: _withCtx(() => [_createVNode(WithdrawalRecord)]),
          _: 1
        }, 8, ["title"]), _createVNode(_component_van_tab, {
          title: _ctx.$t('billing_record')
        }, {
          default: _withCtx(() => [_createVNode(TransactionHistory)]),
          _: 1
        }, 8, ["title"])]),
        _: 1
      }, 8, ["active", "background"])]);
    };
  }
};