// 多个图片 引入处理  star
const req = require.context('./level', true, /\.png$/);
const pngHashMap = new Map();
req.keys().forEach((eachPng) => {
    const imgConfig = req(eachPng);
    const imgName = eachPng.replace(/^\.\/(.*)\.\w+$/, '$1');
    pngHashMap.set(imgName, { imgName, icon: req(eachPng).default || imgConfig });
});
export{
    pngHashMap
}
// 使用   pngHashMap.get('n1')  // n1 位图片名称   end

